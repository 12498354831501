import React from 'react'

import Layout from '@components/layout/Layout'
import FaqGlossaryItem from '@components/faqs/faq-glossary-item/faq-glossary-item.component'

const GlossaryItemHomeSleepApneaTesting = ({ location }) => {
    return (
        <Layout location={location}>
            <FaqGlossaryItem term="home-sleep-testing" />
        </Layout>
    )
}

export default GlossaryItemHomeSleepApneaTesting